<template>
    <div>
        <form-element-group class="mb-2">
            <template v-slot:col-1>
                <form-text-field
                    :label="$t('applications.position')"
                    required
                    :rules="[
                        (v) => !!v || $t('applications.position-required'),
                    ]"
                    :placeholder="$t('applications.enter-position')"
                    icon="how_to_reg"
                    name="position"
                    :value="meta.position"
                    @blur="update('position', $event)"
                ></form-text-field>
            </template>
            <template v-slot:col-2> </template>
        </form-element-group>
        <form-element-group>
            <template v-slot:col-1>
                <form-currency-text-field
                    :placeholder="$t('applications.enter-monthly-income')"
                    :label="$t('applications.monthly-income')"
                    required
                    :rules="[
                        (v) =>
                            !!v || $t('applications.monthly-income-required'),
                    ]"
                    :value="meta.monthly_income"
                    @blur="update('monthly_income', $event)"
                ></form-currency-text-field>
            </template>
            <template v-slot:col-2>
                <form-element
                    :label="$t('applications.employment-type')"
                    required
                >
                    <v-radio-group
                        :value="meta.employment_type"
                        :rules="[
                            (v) =>
                                !!v ||
                                $t('applications.employment-type-required'),
                        ]"
                        row
                        @change="update('employment_type', $event)"
                        required
                    >
                        <v-radio
                            :label="$t('applications.full-time')"
                            value="full"
                        ></v-radio>
                        <v-radio
                            :label="$t('applications.part-time')"
                            value="part"
                        ></v-radio>
                    </v-radio-group>
                </form-element>
            </template>
        </form-element-group>
        <form-element-group class="mb-2">
            <template v-slot:col-1>
                <form-text-field
                    :label="$t('applications.start-date')"
                    required
                    icon="calendar_today"
                    placeholder="mm/dd/yyyy"
                    mask="##/##/####"
                    name="start_date"
                    :value="meta.start_date"
                    :rules="[
                        (v) => !!v || $t('applications.start-date-required'),
                        (v) =>
                            /^((((0[13578])|([13578])|(1[02]))[\/](([1-9])|([0-2][0-9])|(3[01])))|(((0[469])|([469])|(11))[\/](([1-9])|([0-2][0-9])|(30)))|((2|02)[\/](([1-9])|([0-2][0-9]))))[\/]\d{4}$|^\d{4}$/.test(
                                v
                            ) || $t('applications.start-date-invalid'),
                    ]"
                    @input="update('start_date', $event)"
                ></form-text-field>
            </template>
            <template v-slot:col-2>
                <form-text-field
                    v-if="type != 'current'"
                    :label="$t('applications.end-date')"
                    required
                    icon="calendar_today"
                    placeholder="mm/dd/yyyy"
                    mask="##/##/####"
                    name="end_date"
                    :value="meta.end_date || ''"
                    :rules="[
                        (v) => !!v || $t('applications.end-date-required'),
                        (v) =>
                            /^((((0[13578])|([13578])|(1[02]))[\/](([1-9])|([0-2][0-9])|(3[01])))|(((0[469])|([469])|(11))[\/](([1-9])|([0-2][0-9])|(30)))|((2|02)[\/](([1-9])|([0-2][0-9]))))[\/]\d{4}$|^\d{4}$/.test(
                                v
                            ) || $t('applications.end-date-invalid'),
                    ]"
                    @blur="update('end_date', $event)"
                ></form-text-field>
            </template>
        </form-element-group>
    </div>
</template>
<script>
export default {
    name: "employer-meta",
    components: {},
    props: {
        metaData: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
            required: false,
            default: "current",
        },
    },
    data() {
        return {
            meta: this.metaData,
        };
    },
    watch: {},
    methods: {
        update(key, data) {
            this.meta[key] = data;
            this.$emit("update", this.meta);
        },
    },
};
</script>

<style scoped>
</style>