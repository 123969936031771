var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("form-element-group", {
        staticClass: "mb-2",
        scopedSlots: _vm._u([
          {
            key: "col-1",
            fn: function() {
              return [
                _c("form-text-field", {
                  attrs: {
                    label: _vm.$t("applications.position"),
                    required: "",
                    rules: [
                      function(v) {
                        return !!v || _vm.$t("applications.position-required")
                      }
                    ],
                    placeholder: _vm.$t("applications.enter-position"),
                    icon: "how_to_reg",
                    name: "position",
                    value: _vm.meta.position
                  },
                  on: {
                    blur: function($event) {
                      return _vm.update("position", $event)
                    }
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "col-2",
            fn: function() {
              return undefined
            },
            proxy: true
          }
        ])
      }),
      _c("form-element-group", {
        scopedSlots: _vm._u([
          {
            key: "col-1",
            fn: function() {
              return [
                _c("form-currency-text-field", {
                  attrs: {
                    placeholder: _vm.$t("applications.enter-monthly-income"),
                    label: _vm.$t("applications.monthly-income"),
                    required: "",
                    rules: [
                      function(v) {
                        return (
                          !!v || _vm.$t("applications.monthly-income-required")
                        )
                      }
                    ],
                    value: _vm.meta.monthly_income
                  },
                  on: {
                    blur: function($event) {
                      return _vm.update("monthly_income", $event)
                    }
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "col-2",
            fn: function() {
              return [
                _c(
                  "form-element",
                  {
                    attrs: {
                      label: _vm.$t("applications.employment-type"),
                      required: ""
                    }
                  },
                  [
                    _c(
                      "v-radio-group",
                      {
                        attrs: {
                          value: _vm.meta.employment_type,
                          rules: [
                            function(v) {
                              return (
                                !!v ||
                                _vm.$t("applications.employment-type-required")
                              )
                            }
                          ],
                          row: "",
                          required: ""
                        },
                        on: {
                          change: function($event) {
                            return _vm.update("employment_type", $event)
                          }
                        }
                      },
                      [
                        _c("v-radio", {
                          attrs: {
                            label: _vm.$t("applications.full-time"),
                            value: "full"
                          }
                        }),
                        _c("v-radio", {
                          attrs: {
                            label: _vm.$t("applications.part-time"),
                            value: "part"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("form-element-group", {
        staticClass: "mb-2",
        scopedSlots: _vm._u([
          {
            key: "col-1",
            fn: function() {
              return [
                _c("form-text-field", {
                  attrs: {
                    label: _vm.$t("applications.start-date"),
                    required: "",
                    icon: "calendar_today",
                    placeholder: "mm/dd/yyyy",
                    mask: "##/##/####",
                    name: "start_date",
                    value: _vm.meta.start_date,
                    rules: [
                      function(v) {
                        return !!v || _vm.$t("applications.start-date-required")
                      },
                      function(v) {
                        return (
                          /^((((0[13578])|([13578])|(1[02]))[\/](([1-9])|([0-2][0-9])|(3[01])))|(((0[469])|([469])|(11))[\/](([1-9])|([0-2][0-9])|(30)))|((2|02)[\/](([1-9])|([0-2][0-9]))))[\/]\d{4}$|^\d{4}$/.test(
                            v
                          ) || _vm.$t("applications.start-date-invalid")
                        )
                      }
                    ]
                  },
                  on: {
                    input: function($event) {
                      return _vm.update("start_date", $event)
                    }
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "col-2",
            fn: function() {
              return [
                _vm.type != "current"
                  ? _c("form-text-field", {
                      attrs: {
                        label: _vm.$t("applications.end-date"),
                        required: "",
                        icon: "calendar_today",
                        placeholder: "mm/dd/yyyy",
                        mask: "##/##/####",
                        name: "end_date",
                        value: _vm.meta.end_date || "",
                        rules: [
                          function(v) {
                            return (
                              !!v || _vm.$t("applications.end-date-required")
                            )
                          },
                          function(v) {
                            return (
                              /^((((0[13578])|([13578])|(1[02]))[\/](([1-9])|([0-2][0-9])|(3[01])))|(((0[469])|([469])|(11))[\/](([1-9])|([0-2][0-9])|(30)))|((2|02)[\/](([1-9])|([0-2][0-9]))))[\/]\d{4}$|^\d{4}$/.test(
                                v
                              ) || _vm.$t("applications.end-date-invalid")
                            )
                          }
                        ]
                      },
                      on: {
                        blur: function($event) {
                          return _vm.update("end_date", $event)
                        }
                      }
                    })
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }